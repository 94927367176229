import React from 'react';
import {graphql} from 'gatsby';
import Page from '../components/Page';
import CategoryPage from '../components/CategoryPage';

const Categories = ({data}) => {
  return (
    <Page
      title="Categories"
      path="/categories"
      description="Learn about specific topics in tech like venture capital, startups, growth, and design."
      breadcrumb="categories"
    >
      <CategoryPage categories={data.allCategory.edges.map(({node}) => node)} />
    </Page>
  );
};

export default Categories;

export const query = graphql`
  query CategoriesQuery {
    allCategory(
      sort: {fields: [count], order: [DESC]}
      filter: {count: {gte: 1}}
    ) {
      edges {
        node {
          title
          path
          count
        }
      }
    }
  }
`;
