import {Link} from 'gatsby';
import React, {useMemo} from 'react';
import PageTitle from './PageTitle';
import CategoryGroupMap from '../utilities/categoryGroupMap.json';
import CategoryGroups from '../utilities/categoryGroups.json';

import * as styles from './CategoryPage.module.scss';

const CategoryPage = ({categories}) => {
  const handleCategoryClick = (path) => {
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Category',
        event_action: 'Open',
        event_label: path,
      });
  };

  const categoryMarkup = useMemo(() => {
    return CategoryGroups.reduce((acc, group) => {
      const groupCategories = CategoryGroupMap[group];
      if (!groupCategories) {
        return acc;
      }
      const categoryInfo = categories.reduce((arr, {title, path}) => {
        if (groupCategories.includes(title)) {
          arr.push(
            <Link
              id={`${group}-${path}`}
              to={path}
              key={`${group}-${path}`}
              onClick={() => handleCategoryClick(path)}
            >
              {title}
            </Link>,
          );
        }
        return arr;
      }, []);

      acc[group] = categoryInfo;
      return acc;
    }, {});
  }, [categories]);

  return (
    <>
      <div className={styles.Container}>
        <PageTitle title="Topics" path="/categories" />
        {Object.entries(categoryMarkup).map(([key, markup]) => {
          return (
            <div key={key}>
              <h2 className={styles.CategoryHeader}>{key}</h2>
              <div className={styles.CategoriesContainer}>{markup}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CategoryPage;
